import React from "react";
import Layout from "../../../../other/layout";
import ForfaitsCybercommerce from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/SolutionsPourLeCybercommerce/ForfaitsCybercommerce";
import SEO from "../../../../other/seo";

export default function ForfaitsCybercommercePage() {
  return (
    <Layout>
      <SEO title="Forfaits cybercommerce" />

      <ForfaitsCybercommerce />
    </Layout>
  );
}
