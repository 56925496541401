import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/Button";
import gold from "../../../../../assets/img/ecom_gold.gif";
import plat from "../../../../../assets/img/ecom_platinum.gif";
import achetaz from "../../../../../assets/img/achetez.gif";

export default function ForfaitsCybercommerce() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>ForfaitsCybercommerce</h1>

        <p>
          Ne ratez pas une seule occasion de faire une vente, aussi bien à votre
          magasin qui a pignon sur rue que dans votre boutique virtuelle.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.box_mainWrapper}>
          <div className={styles.box_main}>
            <div className={styles.box_content_main}>
              <div className={styles.center}>
                <h2>Cybercommerce professionnel</h2>
                <img alt="Gold Coin" src={gold} />
                <br />
                <span class="price">39,99 $</span>
                <div>/mois</div>
                <a href="http://manage.nebshosting.ca//cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                  <img border="0" src={achetaz} alt="click here to order now" />
                </a>
              </div>
              <div class="box_content_main2">
                <div className={styles.center}>
                  <br />« Vendez des produits sur votre site »
                </div>
                <br />
                <ul>
                  <li>Installation la journée même GRATUITE</li>
                  <li>25 adresses courriel (Classe affaires)</li>
                  <li>
                    <a href="developpez/services-web/solutions-pour-le-cybercommerce/easystoremaker-pro/">
                      EasyStoreMaker Pro
                    </a>{" "}
                    inclus
                  </li>
                  <li>
                    <a href="developpez/services-web/solutions-pour-le-cybercommerce/oscommerce/">
                      osCommerce
                    </a>
                  </li>
                  <li>Modèles de conception graphique professionnelle </li>
                  <li>Certificat protocole SSL</li>
                  <li>
                    Tous les détails au sujet de l'hébergement <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.box_main}>
            <div className={styles.box_content_main}>
              <div className={styles.center}>
                <h2>Cybercommerce avancé</h2>
                <img alt="Platinium Coin" src={plat} />
                <br />
                <span class="price">49,99 $</span>
                <div>/mois</div>
                <a href="http://manage.nebshosting.ca//cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                  {" "}
                  <img border="0" src={achetaz} alt="click here to order now" />
                </a>
              </div>
              <div class="box_content_main2">
                <div className={styles.center}>
                  <br />« Faites affluer les visiteurs sur votre site » <br />
                  Ce forfait comprend toutes les caractéristiques du forfait
                  <br />
                  cybercommerce professionnel, plus :{" "}
                </div>

                <ul>
                  <li>50 adresses courriel (Classe affaires) </li>
                  <li>
                    <a href="developpez/services-web/promotion-de-site-web/easysiteoptimizer/">
                      EasySiteOptimizer
                    </a>
                  </li>
                  <li>Outils de développement </li>
                  <li>Prise en charge de PHP, ASP, ColdFusion, JSP et plus</li>
                  <li>
                    Tous les détails au sujet de l'hébergement
                    <br />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <p className={styles.center}>
          Une boutique virtuelle vous permet d’offrir vos produits et services à
          n’importe qui, n’importe où et n’importe quand. Faites décoller vos
          ventes sur internet rapidement et facilement!
        </p>
      </div>
    </div>
  );
}
